import axios from "@/axios";  

export async function sendWorkLog(workCode) {
    try {
      
      const userInfo = {
        userid: sessionStorage.getItem("adminId"),
        username: sessionStorage.getItem("userName"),
        userType: sessionStorage.getItem("admin_type"),
        loginTime: sessionStorage.getItem("loginTime")
      };
  
      const tokenData = JSON.parse(sessionStorage.getItem("token"));
      const token = tokenData?.access_token || "";
      console.log("userInfo.userType:  "+userInfo.userType);
      if (!userInfo.userid || !token) return;
  
      await axios.post(
        "/access-log",
        {
          user_ID: userInfo.userid,
          user_type: userInfo.userType,
          work_ID: workCode,
          conectAt: userInfo.loginTime,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }       
      );
      console.log("작업 로그 전송 성공!!")
    } catch (err) {
      console.error("작업 로그 전송 실패", err);
    }
  }
  