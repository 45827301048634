export default [
    {
        name: "홈",
        icon: "home",
        to: "/home"
    },
    {
        name: "일정",
        icon: "calendar-alt",  // 아이콘을 설정할 수 있음. FontAwesome 아이콘 중 하나를 선택
        to: "/calendar"  // 링크를 해당 페이지로 설정
    },
    {
        name: "지도",
        icon: "map-marked-alt",
        to: "/map"
    },
    {
        name: "드론 관리",
        icon: "pastafarianism",
        children: [
            {
                name: "드론 로그 목록",
                to: "/drone"
            },
            {
                name: "드론 관리",
                to: "/dronedevice"
            },
        ],
    },
    {
        name: "사용자 관리",
        icon: "users",
        children: [
            {
                name: "회원 목록",
                to: "/users"
            },
            {
                name: "미승인 회원 목록",
                to: "/non-users"
            },
            {
                name: "출석 관리",
                to: "/attendance"
            },
            {
                name: "기수 관리",
                to: "/batch-read"
            },

        ],
    },
    {
        name: "기체 관리",
        icon: "plane",
        children: [
            {
                name: "보험 관리",
                to: "/Insurance-read"
            },
            {
                name: "안정성 인증검사 관리",
                to: "/safetyCert-read"
            },
            {
                name: "비행승인 관리",
                to: "/flightAppv-read"
            }
        ],
    },
    {
        name: "비행경력증명서",
        icon: "certificate",
        children: [            
            {
                name: "발급하기",
                to: "/certificateissue"
            },
            // {
            //     name: "발급하기2",
            //     to: "/certificateissuetest"
            // },
            {
                name: "발급현황",
                to: "/certificatelist"
            }
        ],
    },
    {
        name: "기체비행기록부",
        icon: "book",
        children: [           
            {
                name: "발급하기",
                to: "/logbookissue"
            },            
            {
                name: "발급현황",
                to: "/logbooklist"
            }
        ],
    },
    {
        name: "SMS관리",
        icon: "sms",
        children: [           
            {
                name: "정규 메세지 조회",
                to: "/formedsms-read"
            },  
            {
                name: "정규 메세지 등록",
                to: "/formedsms-create"
            },           
            {
                name: "발송 메세지 등록",
                to: "/sms-create"
            },
            {
                name: "발송 결과 조회",
                to: "/sms-read"
            },
            {
                name: "발송 주기 조회",
                to: "/sms-period-read"
            },
            {
                name: "발송 주기 등록",
                to: "/sms-period-create"
            },
        ],
    },
    {
        name: "Admin관리",
        icon: "user-tie",
        children: [           
            {
                name: "관리자 조회",
                to: "/admin-read"
            },            
            {
                name: "관리자 등록",
                to: "/admin-create"
            },
            
        ],
    },
    {
        name: "마스터 설정",
        icon: "database",
        children: [           
            {
                name: "비행장소",
                to: "/master-airfieldRead"
            },            
            {
                name: "비행목적",
                to: "/master-flightPurposeRead"
            },
            {
                name: "교관정보",
                to: "/master-instructorRead"
            },
            {
                name: "발급기관",
                to: "/master-certOrganRead"
            },
            {
                name: "드론타입",
                to: "/master-droneTypeRead"
            }
        ],
    },
    {
        name: "시스템 설정",
        icon: "cog",
        children: [
            {
                name: "세션타임아웃",
                to: "/sessionTimeout"
            },
            {
                name: "비밀번호 변경",
                to: "/password"
            },
            {
                name: "버전 정보",
                to: "/version"
            },            
            {
                name: "접속기록",
                to: "/access-log"
            },
        ],
    },
];
