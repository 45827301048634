<template>
  <div :class="isAdmin ? 'admin-mode' : 'user-mode'">
    <div v-if="isAdmin">
      <!-- 관리자 모드에서는 사이드 네비게이션 포함 -->
      <mdb-side-nav-2
          :value="true"
          :data="navigation"
          slim
          :slim-collapsed="collapsed"
          @toggleSlim="collapsed = $event"
          sidenav-class="unique-color-dark"
          color="black"
          @itemClick="selectItem"
      >
        <div slot="header">
          <div class="p-1">
            <img
                src="@/assets/logo.png"
                class="img-fluid"
                alt="null"/>
          </div>
          <hr/>
        </div>
        <div slot="content" class="mt-5 d-flex justify-content-center">
          <mdb-btn tag="a" class="mx-0" size="sm" transparent floating
                   :icon="collapsed ? 'chevron-right' : 'chevron-left'" icon-class="white-text"
                   @click="collapseLock"></mdb-btn>
        </div>

        <mdb-navbar
          slot="nav"
          tag="div"
          :toggler="false"
          position="top"
          color="unique-color-dark"      
        >
          <mdb-navbar-nav class="nav-flex-icons" right>
            <mdb-nav-item waves-fixed icon="user-circle" class="userName">
              <span class="ml-1 ">{{ userName }}</span>
            </mdb-nav-item>
            <div class="width40"></div>
            <mdb-nav-item waves-fixed icon="sign-out-alt" class="logout" @click="showAlert">
              <span class="ml-1 ">로그아웃</span>
            </mdb-nav-item>
          </mdb-navbar-nav>
        </mdb-navbar>

          <div slot="main" class="main">
            <router-view class="main-contents"></router-view>
          </div>
        </mdb-side-nav-2>
      </div>
    <div v-else>
    
      <!-- 사용자 모드에서는 상단 네비게이션만 포함 -->
      <div id="user-app">
        <mdb-navbar
            tag="div"
            :toggler="false"
            position="top"
            color="unique-color-dark"
        >
        <mdb-navbar-nav class="nav-flex-icons center-menu" center>
          <!-- 중앙 메뉴 항목 -->
          <router-link to="/UserHome" exact-active-class="active-link">
            <mdb-nav-item waves-fixed>
              <span class="ml-1">홈</span>
            </mdb-nav-item>
          </router-link>
          <router-link to="/Intro" exact-active-class="active-link">
            <mdb-nav-item waves-fixed>
              <span class="ml-1">교육원소개</span>
            </mdb-nav-item>
          </router-link>
          <router-link to="/Schedule" exact-active-class="active-link">
            <mdb-nav-item waves-fixed>
              <span class="ml-1">교육일정</span>
            </mdb-nav-item>
          </router-link>
          <router-link to="/MyPage" exact-active-class="active-link">
            <mdb-nav-item waves-fixed>
              <span class="ml-1">마이페이지</span>
            </mdb-nav-item>
          </router-link>
          
         
        <!-- </mdb-navbar-nav> -->
          <!-- 사용자 정보 및 로그아웃 -->
        <mdb-nav-item waves-fixed icon="user-circle" class="userName">
          <span class="ml-1">{{ userName }}</span>
        </mdb-nav-item>
        <div class="width40"></div>
        <mdb-nav-item waves-fixed icon="sign-out-alt" class="logout" @click="showAlert">
          <span class="ml-1">로그아웃</span>
        </mdb-nav-item>
      </mdb-navbar-nav>
          <!-- <mdb-navbar-nav class="nav-flex-icons right-menu" right >
          <mdb-nav-item waves-fixed icon="user-circle" class="userName">
            <span class="ml-1 ">{{ userName }}</span>
          </mdb-nav-item>
          <div class="width40"></div>
          <mdb-nav-item waves-fixed icon="sign-out-alt" class="logout" @click="showAlert">
            <span class="ml-1 ">로그아웃</span>
          </mdb-nav-item>
        </mdb-navbar-nav> -->
      </mdb-navbar>

        <div class="main">
          <router-view class="main-contents"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdbSideNav2, mdbBtn, waves, mdbNavbar, mdbNavItem, mdbNavbarNav } from "mdbvue";
import adminNavigation from "@/assets/data/adminNavigation";
//import { mapGetters, mapActions } from 'vuex';
import {sendWorkLog} from "@/lib/logModule";

export default {
  name: "MasterView",
  components: {
    mdbSideNav2,
    mdbBtn,
    mdbNavbar,
    mdbNavItem,
    mdbNavbarNav,    

  },
  data() {
    return {
      lock: false,
      show: true,
      collapsed: false,
      navigation: adminNavigation,  // 사이드바 네비게이션     
      isShowModal: false,
      isAdmin: false,
    };
  },
  computed:{
    //...mapGetters(['isAdmin']),
    userName() {
      const adminId = sessionStorage.getItem('adminId');
      const userId = sessionStorage.getItem('userId');
      return adminId ? `${adminId} 관리자님` : `${userId}님`;
    }
  },
  mounted() {
    this.isAdmin = !!sessionStorage.getItem('adminId');
  },
  methods: {
    //...mapActions(['updateAdminMode']),

    selectItem() {
      if (!this.lock) {
        // this.collapsed = true
      }
    },
    collapseLock() {
      this.collapsed = !this.collapsed;
      this.lock = !this.collapsed;
    },
    showAlert() {
      const self = this;
      self.$swal.fire({
        title: '로그아웃 할까요?',
        text: "확인을 누르시면 로그아웃 합니다!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소'
      })
      .then((result) => {
        if (result.isConfirmed) {
          sendWorkLog('000200');
          self.$swal.fire('로그아웃!', '로그아웃 되었습니다.', 'success')
            .then(() => {
              sessionStorage.removeItem('token');
          
              if (self.$route.path !== '/gate') {
                self.$router.replace({ path: '/gate' });
              }
            });
        }
      });
    },
  },
  mixins: [waves],
};
</script>

<style scoped>
.active-link {
  border-bottom: 2px solid #007bff; /* 파란색 언더바 */
  font-weight: bold;
}
.user-mode #user-app {

  @font-face {
  font-family: 'Pretendard';
  src: url('../../assets/fonts/PretendardVariable.ttf') format('truetype');
  font-weight: 100 900;
  font-display: swap;
}

  /* 사용자 모드에서만 적용되는 스타일 */
  
  margin: 0 auto;
  width: 750px;
  min-height: 100%;
  padding-top: 3px;
  border-left: 0; 
  border-right: 0; 
   
   /* border-left: 1px #D8D8D8 solid;
  border-right: 1px #D8D8D8 solid;  */
  position: relative;
  text-align: center;
  
}

/* 여백제거 + 드래그방지 */
body {
  padding: 0;
  margin: 0;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

span.ml-1 {
   font-family: 'Pretendard', sans-serif;
  font-size: 1.0rem;  
  margin-left:0;
  /* margin-right: 20px;  */
  
  float:left; 
  list-style-type:none;
  color: #484848 !important;
  
}
/* 반응형 시작 */

@media (max-width: 750px) {
  .user-mode #user-app {
    width: 100%;
    border-left: 0;
    border-right: 0;
  }
  .userName  {
    display: none !important;
  }

  .logout {
    margin-left: 12px !important; /* 필요에 따라 값 조절 */
  }

  .logout i {
    font-size: 2em !important;
  }
  .center-menu, .right-menu {
    display: flex;
    /* justify-content: space-around; */
     justify-content: space-between; /*항목 간의 간격을 균등하게 조정 */
    /* justify-content:center; */
    /* justify-content: flex-start;  항목들을 왼쪽으로 정렬 메뉴가 화면폭을 넘길때 옵션값 */
    flex-wrap: nowrap;
    font-size: 3vw;    
    font-weight: 300;

    color:#484848;
    overflow-x: scroll;
    white-space: nowrap;   
    -webkit-overflow-scrolling: touch;
    padding-bottom: 11px; /* 글자와 스크롤바 사이 간격 */
    -webkit-scrollbar {
      display:none;  
      -ms-overflow-style:none;  
      scrollbar-width:none;   
    }
    
  }
   
  .userName, .logout {
  flex-shrink: 0;
}
.center-menu, span.ml-1 {
   flex-shrink: 0; /* 메뉴 항목이 축소되지 않도록 설정 */
  flex-basis: auto; /* 항목의 기본 너비를 설정 */
}

  .center-menu > .mdb-nav-item, 
  .right-menu > .mdb-nav-item {
    display: inline-block; /* 항목들이 한 줄에 표시되도록 */
    padding: 0 10px; /* 적절한 여백 추가 */
    margin: 0;
    text-align: center;    
   
  }

  .center-menu > .router-link,
  .right-menu > .router-link {
    flex: 1 ;
    text-align: center;
  }


  .right-menu {
    padding-right: 0;
  }

  .width40 {
    display: none; 
  
  }
  
}

/deep/ .unique-color-dark {
  background: #e6f2ff !important;  
}

.width40 {
  width: 20px;
}

.main-contents {
  margin-top: 56px;
  padding-bottom: 56px;
  min-height: calc(100vh - 56px);
  
}

/deep/ .userName .navbar-link {
  padding-right: 0 !important;
  font-weight: 500;
}

/deep/ .logout .navbar-link {
  padding-left: 0 !important;
  font-weight: 500;
}

/deep/ .unique-color-dark {
  box-shadow: none !important;
}

/deep/ .mdbvue-sidenav__item {
  color: #868686 !important;
}

/deep/ .btn-floating i {
  color: #868686 !important;
}

/deep/ .mdbvue-sidenav__content .active {
  color: #001556 !important;
}
</style>